export function getStoredLocale() {
  let returnLocale = "";
  if (localStorage.getItem("i18nextLng")) returnLocale = localStorage.getItem("i18nextLng");
  if (returnLocale.length > 2) returnLocale = returnLocale.substr(0, 2);
  returnLocale = returnLocale.toLowerCase();
  return returnLocale;
}
export function getCookie(name) {
  var cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}
export function isWindowXS() {
  return window.innerWidth < 576;
}
export function isWindowSM() {
  var winWidth = window.innerWidth;
  return (winWidth >= 576 && winWidth < 768);
}
export function isWindowMD() {
  var winWidth = window.innerWidth;
  return (winWidth >= 768 && winWidth < 992);
}
export function isWindowLG() {
  var winWidth = window.innerWidth;
  return (winWidth >= 992 && winWidth < 1200);
}
export function isWindowXL() {
  var winWidth = window.innerWidth;
  return (winWidth >= 1200 && winWidth < 1400);
}
export function isWindowXXL() {
  return window.innerWidth >= 1400;
}
export function isEmptyVar(vari) {
  if (typeof vari === "undefined" || vari === undefined || vari === null || vari === "") return true;
  return false;
}
export function isArray(value) {
  return value && value !== undefined && typeof value === 'object' && value.constructor === Array;
}
export function isObject(value) {
  return value && value !== undefined && typeof value === 'object' && value.constructor === Object;
}
export function isEmptyArray(arr) {
  let isEmpty = true;
  if (isArray(arr) && arr.length > 0) isEmpty = false;
  return isEmpty;
}
export function isEmptyObject(object) {
  let isEmpty = true;
  if (isObject(object)) {
    for (let prop in object) {
      if (object.hasOwnProperty(prop)) {
        isEmpty = false;
        break
      }
    }
  }
  return isEmpty;
}
export function createFormDataFromState(dataObj, exception, only) {
  let postFormData = new URLSearchParams(), tempVal, i, j;
  if (!isArray(exception)) exception = [];
  if (!isArray(only)) only = [];
  Object.keys(dataObj).forEach(function (key, index) {
    if (
      exception.indexOf(key) < 0 &&
      (
        (!isEmptyArray(only) && only.indexOf(key) >= 0) || isEmptyArray(only)
      )
    ) {
      tempVal = dataObj[key];
      if (isArray(tempVal))
        for (i = 0; i < tempVal.length; i++) {
          if (isArray(tempVal[i]))
            for (j = 0; j < tempVal[i].length; j++) postFormData.append(key + "[" + i + "][" + j + "]", tempVal[i][j]);
          else
            postFormData.append(key + "[" + i + "]", tempVal[i]);
        }
      else
        postFormData.append(key, tempVal);
    }
  });
  return postFormData;
}