import * as ACTIONS from "../constants/action-types.js";

const initialState = {
  pageData: {
    title: "",
    appBarTitle: ""
  }
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SET_PAGE_DATA:
      return Object.assign({}, state, { pageData: Object.assign({}, action.payload) });
    default:
      break;
  }
  return state;
};

export default rootReducer;