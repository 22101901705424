import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function Footer() {
  const yearNow = new Date().getFullYear();
  return (
    <footer  className="bg-gray-200">
      <Container>
        <Row>
          <Col>
            <div className="text-center">
              <p className="my-4 text-sm">
                ©{yearNow} - webtech.id
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;