import React from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';

import NavigationBar from './NavigationBar.js';
import Footer from './Footer.js';
import * as CONFIG from '../config.js';

function Template(props) {
  return (
    <>
      <Helmet>
        <title>
          {
            props.pageData.title !== "" ?
              props.pageData.title + " | "
              :
              null
          }
          {CONFIG.APP_NAME}
        </title>
      </Helmet>
      <NavigationBar />
      {props.children}
      <Footer />
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return Object.assign({}, ownProps, { pageData: state.pageData });
};

export default connect(mapStateToProps)(Template);