import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import ScrollToTop from './Components/ScrollToTop.js';
import Template from './Components/Template.js';
import Localizer from './Localizer.js';
import AppRoutes from './AppRoutes.js';

import './assets/css/nucleo-icons.css';
import './assets/css/nucleo-svg.css';
import './assets/css/soft-design-system.css';
import './assets/css/style/main.css';

function App() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const languageData = [
    {
      code: "en"
    },
    {
      code: "id"
    }
  ];

  const supportsHistory = 'pushState' in window.history;
  return (
    <BrowserRouter forceRefresh={!supportsHistory}>
      <Localizer availableLanguage={languageData} changeLanguage={changeLanguage}>
        <ScrollToTop />
        <Template>
          <AppRoutes />
        </Template>
      </Localizer>
    </BrowserRouter>
  );
}

export default App;