import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getStoredLocale } from './Helper/main.js';
import * as CONFIG from './config.js';

function checkIsLangExist(availableLanguage, lang) {
  let isExist = false;
  let loopLength = availableLanguage.length;
  for (let i = 0; i < loopLength; i++)
    if (lang === availableLanguage[i].code) {
      isExist = true;
      break;
    }
  return isExist;
}

function getLocaleFromPath(availableLanguage, path) {
  let returnLocale = getStoredLocale();
  if (!checkIsLangExist(availableLanguage, returnLocale)) returnLocale = CONFIG.DEFAULT_LOCALE;

  if (path !== "" && path !== "/") {
    const pathLocale = path.split('/')[1];
    if (pathLocale && checkIsLangExist(availableLanguage, pathLocale.toLowerCase())) returnLocale = pathLocale;
  }

  returnLocale = returnLocale.toLowerCase();
  return returnLocale;
}

function Localizer(props) {
  let hookLocation = useLocation();
  const { availableLanguage, changeLanguage } = props;
  const setLocale = (newLocale) => {
    if (newLocale !== getStoredLocale()) changeLanguage(newLocale);
  };

  useEffect(() => {
    setLocale(getLocaleFromPath(availableLanguage, hookLocation.pathname));
  }, [hookLocation]);

  return props.children;
}

export default Localizer;