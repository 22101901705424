import React, { lazy } from 'react';
import { Routes, Route } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const BaseNavigate = lazy(() => import('./Components/BaseNavigate.js'));
const NotFound404 = lazy(() => import('./Pages/404.js'));
const Services = lazy(() => import('./Pages/Services.js'));
const ContactUs = lazy(() => import('./Pages/ContactUs.js'));
const Home = lazy(() => import('./Pages/Home.js'));

function AppRoutes() {
  const { i18n } = useTranslation();
  const langPath = "/" + i18n.language;
  return (
    <Routes>
      <Route exact
        path={langPath + "/404"}
        element={<NotFound404 />}
      />
      <Route exact
        path={langPath + "/services"}
        element={<Services />}
      />
      <Route exact
        path={langPath + "/contact-us"}
        element={<ContactUs />}
      />
      <Route exact
        path={langPath}
        element={<Home />}
      />
      <Route exact
        path="/"
        element={<BaseNavigate toPath={langPath} />}
      />
      <Route path="*" element={<NotFound404 />} />
    </Routes>
  );
}

export default AppRoutes;