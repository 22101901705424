import React from 'react';
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavItem from 'react-bootstrap/NavItem';
import NavLink from 'react-bootstrap/NavLink';
import Dropdown from 'react-bootstrap/Dropdown';

import { isEmptyVar } from '../Helper/main.js';

import logo from '../assets/img/logos/webtech-logo.png';
import flagId from '../assets/img/flags/indonesia.png';
import flagEn from '../assets/img/flags/uk.png';

import '../assets/css/style/navigation.css';

function NavigationBar() {
  const { t, i18n } = useTranslation();
  let langStr = i18n.language;

  let hookLocation = useLocation();
  let pathName = hookLocation.pathname;
  pathName = pathName.split('/');
  if(!isEmptyVar(pathName[2])){
    pathName.shift();
    pathName.shift();
    pathName = "/" + pathName.join('/');
  }
  else pathName = "";

  return (
    <Container className="position-sticky z-index-sticky top-0">
      <Row>
        <Col>
          <Navbar bg="none" variant="none" expand="lg" className="blur blur-rounded top-0 z-index-fixed shadow position-absolute my-3 py-2 start-0 end-0 mx-4">
            <Container className="px-0" fluid>
              <Navbar.Brand as={Link} to={"/" + langStr}>
                <img
                  src={logo}
                  width="138"
                  height="25"
                  className="d-inline-block align-top"
                  alt="webtech.id logo"
                />
              </Navbar.Brand>
              <Navbar.Toggle className="shadow-none ms-2" aria-controls="navigation" aria-expanded={false}>
                <span className="navbar-toggler-icon mt-2">
                  <span className="navbar-toggler-bar bar1"></span>
                  <span className="navbar-toggler-bar bar2"></span>
                  <span className="navbar-toggler-bar bar3"></span>
                </span>
              </Navbar.Toggle>
              <Navbar.Collapse
                className="w-100 pt-3 pb-2 py-lg-0"
                id="navigation"
              >
                <Nav as="ul" className="navbar-nav-hover w-100">
                  <Nav.Item as="li" className="ms-lg-auto mx-2">
                    <Nav.Link as={Link} to={"/" + langStr + "/services"} className="ps-2 d-flex justify-content-between cursor-pointer align-items-center">{t("servicesMenu")}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="mx-2">
                    <Nav.Link as={Link} to={"/" + langStr + "/contact-us"} className="ps-2 d-flex justify-content-between cursor-pointer align-items-center">{t("contactUsMenu")}</Nav.Link>
                  </Nav.Item>
                  <Dropdown as={NavItem} className="dropdown-hover">
                    <Dropdown.Toggle as={NavLink} className="nav-link-icon me-2" id="dropdownMenuPages" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="fas fa-globe me-1"></i>
                      <p className="d-inline text-sm z-index-1 font-weight-bold" title="Change Language">{langStr.toUpperCase()}</p>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-animation p-3 border-radius-lg mt-0 mt-lg-3" aria-labelledby="dropdownMenuPages">
                      <Dropdown.Item as={Link} to={"/id" + pathName} className="border-radius-md">
                        <img className="nav-flag-icon" src={flagId} alt="id" />
                        &nbsp;
                        ID
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to={"/en" + pathName} className="border-radius-md">
                        <img className="nav-flag-icon" src={flagEn} alt="en" />
                        &nbsp;
                        EN
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Col>
      </Row>
    </Container>
  );
}

export default NavigationBar;